import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Helmet } from 'react-helmet'
import favicon from '../images/favicon.png'
import Banner from '../components/banner'
import Nav from '../components/nav'
import ArticleCard from '../components/article_card'

export default function BlogPost({ data }) {
  const post = data.thisPost
  const allPosts = data.allPosts.posts

  return (
    <>
      <Helmet>
        <title>{post.frontmatter.title}</title>
        <link rel="icon" href={favicon} />
      </Helmet>

      <Banner />
      <Nav />

      <main className="max-w-screen-lg container mx-auto pt-8">
        <div className="md:flex">
          <article className="md:mr-8">
            <header>
              <h1 className="text-4xl text-ediy-700 md:w-full w-10/12 mx-auto">{post.frontmatter.title}</h1>
              <h2 className="text-2xl mt-4 text-ediy md:w-full w-10/12 mx-auto">{post.frontmatter.description}</h2>
              <GatsbyImage image={getImage(post.frontmatter.image)} className="h-full md:w-full w-10/12 mx-auto block my-4 rounded shadow-lg" />
            </header>
            <section className="w-10/12 mx-auto mt-8 markdown" dangerouslySetInnerHTML={{ __html: post.html }} />
          </article>
          <div className="md:flex-shrink-0 w-full md:w-80">
            <h2 className="font-bold text-2xl text-gray-900 mb-4">Recent Posts</h2>
            {allPosts.map(post => (
              <ArticleCard post={post} size="small"/>
            ))}
          </div>
        </div>
      </main>
    </>
  )
}

export const query = graphql`
  query BlogQuery($slug: String!) {
    thisPost: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 1440
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      html
    }
    allPosts: allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          description
          category
          slug
          image {
            childImageSharp {
              gatsbyImageData(
                width: 1440
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`

